import React, { useState, useContext, useEffect } from 'react';
import useAPI from '../../NetworkCall/API';
import appContext from '../../Context/appContext';
// import appConfig from '../../appConfig'
import DangerousHTML from '../DangerousHTML'
import { useConfigData } from '../../Context/ConfigProvider';

export default function GrassdoorBot({ messageData, clickHandler, orderId, dateTime, totalMessages, index }) {
  const {
    state: {
      chat: { selectedOptions, data, chatMessages = [], displayChat },
    }
  } = useContext(appContext);
  // const imageBaseUrl = 'https://saas4-operator1-public-data.s3.us-west-2.amazonaws.com/Users/Grassdoor/acs/defective_product_image/'
  const appConfig = useConfigData();
  const [disabled, setDisabled] = useState(false)
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState('')
  const [uploadProgress, setUploadProgress] = useState(null);
  const [images, setImages] = useState([])
  const [imageUrls, setImageUrls] = useState([])

  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedIssue, setSelectedIssue] = useState('')
  const [selectedOrder, setSelectedOrder] = useState('')

  const { addChatBotFeedback, uploadChatBotImages } = useAPI();

  const submitHandler = async () => {
    if (comment.trim() === '') {
      setCommentError('Please enter comment')
      return;
    }
    setDisabled(true)
    const issueData = {
      issue_list_id: selectedOptions.issue_list_id,
      product_id: selectedOptions.issue_category_id === 1 ? selectedOptions.product_id ? selectedOptions.product_id : null : null,
      comment: comment,
      file_names: imageUrls,
      // orderId: orderId
    }
    if (issueData.product_id === null) {
      delete issueData.product_id
    }
    clickHandler({ type: 'gd', message: '<div class="loader-text"><span class="inline-loader"></span> Please wait. We are processing your request...</div>', messageType: "issueProcessing", event: "issueProcessing" })
    const response = await addChatBotFeedback(issueData, orderId)
    const selected = { comment: comment, file_names: imageUrls, submitted_product_id: selectedOptions.issue_category_id === 1 ? selectedOptions.product_id : null, submitted_category_id: selectedOptions.issue_category_id === 1 ? null : selectedOptions.issue_category_id }
    if (response.status === true) {
      setDisabled(true)
      selected.complaint_id = response.data.complaint_id
      setTimeout(() => {
        clickHandler({ type: 'gd', message: response.message, event: "issueSubmited" }, selected)
      }, 4000)
      if (response.data.redirect_to_zendesk === true) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          const windowReference = window.open();
          setTimeout(() => {
            windowReference.location = appConfig.FAQ_URL;
          }, 8000)
        } else {
          setTimeout(() => {
            window.open(appConfig.FAQ_URL, '_blank');
          }, 8000)
        }
      }
    } else {
      selected.complaint_id = 'false'
      clickHandler({ type: 'gd', message: response.error.data.message, event: "manageMediaFalse" }, selected)
    }
  }

  const onSelectImage = async (e) => {
    const fileObj = [...e.target.files];
    setDisabled(true)
    const image = URL.createObjectURL(fileObj[0]);
    setImages(oldArray => [...oldArray, image]);
    const { data } = await uploadChatBotImages(fileObj[0], progress => { setUploadProgress(progress) });
    setDisabled(false)
    if (data.success === true) {
      setImageUrls(oldArray => [...oldArray, data.result[0].image_name]);
      clickHandler({ type: 'gd', images: [...imageUrls, data.result[0].image_name], event: "manageMedia" })
    }
  }

  const removeImage = (index) => {
    setImages([
      ...images.slice(0, index),
      ...images.slice(index + 1)
    ]);

    setImageUrls([
      ...imageUrls.slice(0, index),
      ...imageUrls.slice(index + 1)
    ]);

    clickHandler({ type: 'gd', images: [...images.slice(0, index), ...images.slice(index + 1)], event: "manageMedia" })
  }
  const clickHandlerOptions = async (data) => {
    if (data.selected.issue_category_id === 4) {
      const issueData = {
        issue_list_id: selectedOptions.issue_list_id,
        product_id: selectedOptions.issue_category_id === 1 ? selectedOptions.product_id ? selectedOptions.product_id : null : null,
        comment: comment,
        file_names: imageUrls,
        // orderId: orderId
      }
      if (issueData.product_id === null) {
        delete issueData.product_id
      }
      clickHandler({ type: 'gd', message: '<div class="loader-text"><span class="inline-loader"></span> Please wait. We are processing your request...</div>', messageType: "issueProcessing", event: "issueProcessing" })
      const response = await addChatBotFeedback(issueData, orderId)

      if (response.status === true) {
        clickHandler({ ...data, message: response.error.data.errors.length === 0 })
      } else if (response.error?.data?.errors?.length == 0) {
        clickHandler({ ...data, message: response.error.message })
      } else {
        clickHandler({ ...data, 
          message: (
            <>
              We're unable to process your request at this time. Please contact a customer service representative and we'll be happy to assist you.
              <a href={`tel:${appConfig.SUPPORT_CONTACT}`} className="ml-[5px] text-decoration-none">
                {appConfig.SUPPORT_CONTACT}
              </a>
            </>
          )
        });
      }
    } else {
      clickHandler(data)
    }

  }
  useEffect(() => {
    if (messageData?.images?.length) {
      setImages(messageData.images)
      setImageUrls(messageData.images ? messageData.images : messageData)
    }

    if (messageData?.comment) {
      setComment(messageData.comment)
    }
  }, [messageData.images, messageData.comment])

  useEffect(() => {
    if (totalMessages > index && selectedOptions?.issue_list_id) {
      setDisabled(true)
    }
  }, [totalMessages])

  useEffect(() => {
    if (displayChat?.disabled === true) {
      setTimeout(() => {
        setSelectedProduct(selectedOptions?.product_id ? selectedOptions.product_id : '')
        setSelectedCategory(selectedOptions?.issue_category_id ? selectedOptions.issue_category_id : '')
        setSelectedIssue(selectedOptions?.issue_list_id ? selectedOptions.issue_list_id : '')
      }, 100)
    }
  }, [selectedOptions, displayChat.disabled])

  return (
    <>
      <div className="default-bot d-flex">
        <div className="g-holder d-none">G</div>
        <div className="chat-holder w-100">
          <DangerousHTML html={messageData.message} className="chat-text" />
          {
            messageData?.messageType === 'issueCategoryList' && (
              <div className="chat-options">

                {
                  data?.issue_category?.length && data?.issue_category?.map((list) => {
                    return (
                      <div className="custom-radio">
                        <input disabled={index + 3 < chatMessages.length ? true : false} checked={selectedCategory === list.id ? true : false} type="radio" id={`issueCategory-${index}-${list.id}`} name={`issueCategory-${index}`} onChange={() => {
                          if (index + 3 >= chatMessages.length) {
                            // setDisabled(true);  
                            setSelectedCategory(list.id)
                            clickHandlerOptions({
                              type: 'user',
                              message: list.label,
                              selected: { issue_category_id: list.id, issue_category_label: list.label, chatOpen: true, index: index, complaint_id: '' },
                              event: "categorySelected"
                            })
                          }
                        }
                        } />
                        <label for={`issueCategory-${index}-${list.id}`} className={`options ${index + 3 < chatMessages.length ? 'disabled' : ''}`} > {list.label} </label>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
          {
            (messageData?.options || messageData?.order_details) && (
              <div className="chat-options">
                {
                  messageData?.options && messageData?.options.length ? messageData?.options.map((option, ind) => {
                    return (
                      messageData.messageType === 'issueList' ?
                        <div key={ind} className="custom-radio">
                          <input disabled={index + 3 < chatMessages.length ? true : false} checked={selectedIssue === option.issue_id ? true : false} id={`issue-${index}-${option.issue_id}`} type="radio" name={`issues-${index}`} onChange={() => {
                            if (index + 3 < chatMessages.length === false) {
                              // setDisabled(true);  
                              setSelectedIssue(option.issue_id)
                              clickHandler({ type: 'user', message: option.issue_name, selected: { issue_list_id: option.issue_id, issue_index: ind, issue_name: option.issue_name, message: option.message, index: index }, event: "issueSelected" }, { issue_index: index })
                            }
                          }
                          } />
                          <label for={`issue-${index}-${option.issue_id}`} className={`options ${index + 3 < chatMessages.length ? 'disabled' : ''}`} >{option.issue_name}</label>
                        </div>
                        :
                        ''
                    )
                  })
                    :
                    null
                }
                {
                  messageData?.order_details ? messageData.order_details.products.map((product, pind) => {
                    return (
                      <div key={pind} className="custom-radio">
                        <input disabled={index + 3 < chatMessages.length} checked={selectedProduct === product.product_id ? true : false} id={`product-${index}-${product.product_id}`} type="radio" name={`products-${index}`} onChange={() => {
                          if (index + 3 < chatMessages.length === false) {
                            if (data.filtered_products.includes(product.product_id) === false) {
                              // setDisabled(true); 
                              setSelectedProduct(product.product_id)
                              clickHandler({ type: 'user', message: product.name, selected: { product_id: product.product_id, index: index }, image_url: product.image_url, event: 'productSelected' })
                            }
                          }
                        }
                        } />
                        <label for={`product-${index}-${product.product_id}`} className={`options product-options ${index + 3 < chatMessages.length || data.filtered_products.includes(product.product_id) ? 'disabled' : ''} ${data.filtered_products.includes(product.product_id) ? 'tooltip' : ''}`}>
                          <img src={product.image_url} alt="" />
                          <span>{product.name} </span>
                          {data.filtered_products.includes(product.product_id) && <div className="tooltiptext">{'Already Requested'}</div>}
                        </label>
                      </div>
                    )
                  })
                    :
                    null
                }
              </div>
            )
          }
          {
            messageData?.addPhotos && (
              <div className="add-details">
                <div className="title">{messageData?.settings?.upload_images ? 'Upload photos and add comments' : 'Add comments'}</div>
                {messageData?.settings?.upload_images ? <div className="sub-text">Capture photos of product with visible defects. Wait for the photos to upload</div> : null}
                {
                  messageData?.settings?.upload_images ?
                    <div className="d-flex flex-wrap">
                      {
                        images.length ? images.map((url, key) => {
                          return (
                            <div className="img-holder" key={key}>
                              <img src={url.includes('blob') ? url : process.env.NEXT_PUBLIC_ACS_IMAGE_URL + url} alt="chat uploads" />
                              {disabled === false && <span className='icon-minus' onClick={() => removeImage(key)}></span>}
                            </div>
                          )
                        }) : null
                      }
                      {
                        uploadProgress && uploadProgress !== 100 ?
                          <div className="d-flex">
                            <div className="img-holder d-flex justify-content-center align-items-center loader" >

                            </div>
                          </div>
                          :
                          null
                      }
                      {disabled === false && <input className="upload" type="file" id="chatImageUploads" name="chatImageUploads" accept="image/*" onChange={onSelectImage} />}
                    </div>
                    :
                    null
                }
                <div className="form-group mt-3">
                  <label className="">Comments<span className="red-color pl-1">*</span></label>
                  <textarea
                    className="form-control phone-number br-0"
                    placeholder="Mention the details of the issue"
                    autoComplete="one-time-code"
                    disabled={disabled}
                    defaultValue={comment}
                    id={`comment-${index}`}
                    name={`comment-${index}`}
                    maxLength={250}
                    onChange={(e) => {
                      setCommentError(''); setComment(e.target.value)
                      clickHandler({ type: 'gd', comment: e.target.value, event: "manageComment" })
                    }}
                  >
                  </textarea>
                  <div className='d-flex justify-content-space-between'>
                    <div className='error'>{commentError}</div>
                    <div className='length-text fs-12'>{comment.length} of {250}</div>
                  </div>
                </div>
                {
                  disabled === false ?
                    <div className="text-right">
                      <button onClick={() => submitHandler()} className="btn btn-primary has-box-shadow rouded-button">Submit</button>
                    </div>
                    :
                    null
                }
              </div>
            )
          }
          {
            messageData.messageType === 'yes-no' ?
              <div className="chat-options yes-no">
                <div className="custom-radio">
                  <input type="radio" id={`yes-${index}`} name={`yes-no-${index}`} onClick={() => {
                    if (disabled === false) {
                      setDisabled(true)
                      clickHandler({ type: 'user', message: "Yes", selected: { anyIssue: 'Yes', complaint_id: '' }, event: "anyMoreIssue" })
                    }
                  }
                  } />
                  <label for={`yes-${index}`} className={`options ${disabled ? 'disabled' : ''}`}>
                    Yes
                  </label>
                </div>
                <div className="custom-radio">
                  <input type="radio" id={`no-${index}`} name={`yes-no-${index}`} onClick={() => {
                    if (disabled === false) {
                      setDisabled(true)
                      clickHandler({ type: 'user', message: "No", selected: { anyIssue: 'No', complaint_id: '' }, event: "anyMoreIssue" })
                    }
                  }
                  } />
                  <label for={`no-${index}`} className={`options ${disabled ? 'disabled' : ''}`} >
                    No
                  </label>
                </div>
              </div>
              :
              null
          }
          {
            messageData.messageType === 'wasHelpfull' ?
              <div className="chat-options helpful">
                <div className={`options ${disabled ? 'disabled' : ''}`} onClick={() => {
                  if (disabled === false) {
                    setDisabled(true)
                    clickHandler({ type: 'user', message: "Yes, thank you", selected: { wasThishelpfull: 'Yes' }, event: "wasHelpfull" })
                  }
                }
                }>
                  <div className="d-flex align-items-center">
                    Yes, thank you
                    <span class="icon-thumbs-up1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                  </div>
                </div>
                <div className={`options ${disabled ? 'disabled' : ''}`} onClick={() => {
                  if (disabled === false) {
                    setDisabled(true)
                    clickHandler({ type: 'user', message: "No, this wasn't helpful", selected: { wasThishelpfull: 'No' }, event: "wasHelpfull" })
                  }
                }
                }>
                  <div className="d-flex align-items-center">
                    No, this wasn't helpful
                    <span class="icon-thumbs-down-color"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                  </div>
                </div>
              </div>
              :
              null
          }
          {
            messageData.messageType === 'orderList' ?
              <div className="chat-options">
                {
                  data?.order_list?.map((option, index) => {
                    return (<div className='custom-radio'>
                      <input
                        disabled={chatMessages.length > 1 ? true : false}
                        checked={selectedOrder === option.order_id ? true : false}
                        id={`order-${index}-${option.order_id}`}
                        type="radio"
                        name={`orders-${index}`}
                        onChange={() => {
                          setSelectedOrder(option.order_id)
                          clickHandler({ type: 'user', message: `#${option.order_id}`, selected: option, event: "orderSelected" }, { issue_index: index })

                        }}
                      />
                      <label for={`order-${index}-${option.order_id}`} className={`options flex flex-col ${chatMessages.length > 1 ? 'disabled' : ''}`} >
                        <div className=' w-full mb-2 flex justify-between font-bold text-[#262626F2]'>
                          <h6># {option.order_id}</h6>
                          <h6>{option.formated_date_time}</h6>
                        </div>
                        <p className='text-[#26262699] w-full'>{option.formatted_products}</p>
                      </label>

                    </div>)
                  })
                }
              </div>
              : null
          }
          <div className="time">{dateTime}</div>
        </div>
      </div>
    </>
  );
}