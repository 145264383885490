import storageService from '../services/storageService';

export function createInitialStore() {
  // const chatData = storageService.GetChatBoxData()
  const chatData = {}
  const data = chatData?.data ? chatData.data : []
  const displayChat = chatData?.displayChat ? { ...chatData.displayChat, ...{ reload: true } } : { displayChat: false }
  const chatMessages = chatData?.chatMessages ? chatData.chatMessages : []
  const selectedOptions = chatData?.selectedOptions ? chatData.selectedOptions : {};
  const loading = false;
  return { data, displayChat, selectedOptions, chatMessages, loading };
}

export const actions = {};

actions.manageChatBox = (state, action) => {

  const data = action.payload.selectedIssue ?
    { ...state.chat, displayChat: action.payload.displayChat, selectedOptions: { issue_category_id: action.payload.selectedIssue.id, issue_category_label: action.payload.selectedIssue.label } }
    :
    {
      ...state.chat,
      displayChat: { ...state.chat.displayChat, ...action.payload.displayChat },
      chatMessages: action.payload.displayChat.show === false ? [] : action.payload.restart === true ? action.payload.chatMessages : state.chat.chatMessages,
      selectedOptions: action.payload.displayChat.show === false ? {} : action.payload.restart === true ? {} : state.chat.selectedOptions
    }

  // storageService.SetChatBoxData(data)

  return {
    ...state,
    chat: data
  };
};

actions.setChat = (state, action) => {
  const data = { ...state.chat, ...action.payload, displayChat: { show: true, disabled: true, reload: true } }
  // storageService.SetChatBoxData(data)
  return {
    ...state,
    chat: data
  };
};

actions.setLoading = (state, action) => {
  return {
    ...state,
    chat: {
      ...state.chat,
      loading: action.payload
    }
  }
}

actions.setChatData = (state, action) => {
  const data = { ...state.chat, data: action.payload }
  // storageService.SetChatBoxData(data)
  return {
    ...state,
    chat: data
  };
};

actions.updateLastMessage = (state, action) => {
  const allMessages = state.chat.chatMessages
  const lastMessage = { ...allMessages[allMessages.length - 1], ...action.payload }
  allMessages[allMessages.length - 1] = lastMessage
  const chats = {
    ...state.chat,
    chatMessages: allMessages
  }
  // storageService.SetChatBoxData(chats)
  return {
    ...state,
    chat: chats
  };
}

actions.removeMessage = (state, action) => {
  if (state.chat.chatMessages?.[state.chat.chatMessages.length - 1]?.event == 'issueProcessing') {
    return {
      ...state,
      chat: {
        ...state.chat,
        chatMessages: state.chat.chatMessages.slice(0, -1)
      }
    }
  } else {
    return state
  }



}

actions.addMessage = (state, action) => {
  let selectedObject = null
  let product_id
  let category_id
  action.payload.messages.map((msg) => {
    if (msg.selected) {
      selectedObject = { ...selectedObject, ...msg.selected }
      if (msg.selected.submitted_product_id) { product_id = msg.selected.submitted_product_id }
      if (msg.selected.submitted_category_id) { category_id = msg.selected.submitted_category_id }
    }
    return null
  })

  const chatObject = {
    ...state.chat,
    chatMessages:
      action.payload.messages
        ?
        action.payload.clear
          ?
          action.payload.messages
          :
          [...state.chat.chatMessages, ...action.payload.messages]
        :
        [...state.chat.chatMessages, action.payload],
    selectedOptions: { ...state.chat.selectedOptions, ...selectedObject },
    data: product_id ?
      { ...state.chat.data, filtered_products: state.chat.data.filtered_products.includes(product_id) ? state.chat.data.filtered_products : [...state.chat.data.filtered_products, product_id] }
      : category_id ?
        { ...state.chat.data, issue_categories_reported: [...state.chat.data.issue_categories_reported, category_id] }
        : state.chat.data
  }

  // storageService.SetChatBoxData(chatObject)
  return {
    ...state,
    chat: chatObject
  };
};