import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { useMyOrders, useReferralEarned } from '../hooks/User';
import ActiveLink from './ActiveLink';
import useSegment from '../hooks/segment';
import { useFullCategoryList } from '../hooks/Shop';
import { DEFAULT_CATEGORY_MENU } from '../constants/default';
// import appConfig from '../appConfig';
import isMobile from '../helpers/isMobile';
import { useDispatch, useSelector } from 'react-redux';
import { increaseURLstack, pushNotification } from '../redux/slices/modalSlice';
import { setUrlBeforeProduct, toggleSidebar } from '../redux/slices/userSlice';
import getDefaultTab from '../helpers/getDefaultTab';
import { useDeliveryDetails } from '../hooks/app';
import { useConfigData } from '../Context/ConfigProvider';
import appContext from '../Context/appContext';
import { isACSApplicable } from '../constants/feature';
import useAPI from '../NetworkCall/API';
import { setRollbarWarning } from '../helpers/rollBar';

function NewSidebar({
  router,
  initialFullCategoriesList,
  initialDeliveryAddressDetails
}) {
  const {
    query: { tab },
    pathname
  } = router;

  const { dispatch } = useContext(appContext)
  const appConfig = useConfigData();
  const [openedSubmenu, setOpenedSubmenu] = useState(isMobile() ? null : 'Categories');
  const {
    data: { ambassador: { is_ambassador: isAmbassador } = {} }
  } = useReferralEarned();
  const { trackEvent } = useSegment();

  const {
    data: { count: orderCount }
  } = useMyOrders({ offset: 0 });
  const isSideBarOpen = useSelector(state => state.user.sideBar.isSideBarOpen)
  const { fullCategoryList } = useSelector(state => state.sidebar) || {}
  const isLoggedIn = useSelector(state => state.user?.isLoggedIn) || false;
  const dispatchRedux = useDispatch();
  const { getResolvableOrderList } = useAPI()

  let imageSrc = appConfig.HEADER_LOGO_COLOR;

  const sideBarList = [
    {
      icon: 'icon icon-Categories-sidebari',
      name: 'Categories',
      hasSubMenu: fullCategoryList
      // fullCategoryList.length &&
      // fullCategoryList?.map(item => {
      //   return { ...item, category: true };
      // })
    },
    ...(!appConfig.hideBrandPage ? [{
      icon: 'icon icon-Brand',
      name: 'Shop by brands',
      to: '/brands'
    }] : [])
    ,
    // {
    //   icon: 'icon icon-gift-outline',
    //   name: 'Gift cards',
    //   hasSubMenu: giftCadListGd
    // },
    // ...(isAmbassador
    //   ? [
    //     {
    //       to: '/referral',
    //       icon: 'icon icon-share-circles',
    //       name: 'Ambassadors'
    //       // hide: orderCount === 0
    //     }
    //   ]
    //   : [
    //     {
    //       to: '/referral',
    //       icon: 'icon icon-share-circles',
    //       name: 'Refer Your Friends',
    //       hide: orderCount === 0
    //     }
    //   ]),
    // {
    //   icon: 'icon icon-info',
    //   name: 'About Us',
    //   to: '/aboutus'
    // },
    {
      icon: 'icon icon-question-thin',
      name: 'FAQs',
      to: '/faq'
    }
    // {
    //   icon: 'icon icon-blog-dark',
    //   name: 'Blog',
    //   to: '/blog'
    // }
  ];
  function handleNavigationClick(e, href, name, label, directExternalLink) {
    debugger;
    e.preventDefault();
    const payload = {
      category: 'Navigation',
      label,
      value: name,
      action: 'Click'
    };
    trackEvent(name, payload);
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())
    directExternalLink ? window.open(href, '_blank') : router.push(href);
    // router.push(href);
  }
  function handleBackdropClick(name, label) {
    const payload = {
      category: 'Navigation',
      label,
      value: name,
      action: 'Click'
    };
    trackEvent(name, payload);
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())
  }
  const handleCategoryClick = data => {
    const payload = {
      category: 'Navigation',
      value: data.name,
      action: 'Click'
    };
    trackEvent(data.name, payload);
    // const url = new URL(data.link);
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())

    // router.push(`${url.pathname}${tab && tab.length ? `?tab=${tab}` : ''}`);
  };

  function showHideSubmenu(name) {
    setOpenedSubmenu(openedSubmenu !== name ? name : null);
  }

  // const handleNavigate = link => {

  //   const url = new URL(link);
  //   return `${url.pathname}${tab && tab.length ? `?tab=${tab}` : ''}`;
  // };
  const handleMenuPopupClick = item => () => {
    if (item.to !== '/') {
      dispatchRedux(increaseURLstack())
      // dispatch({
      //   type: 'setUrlBeforeProduct',
      //   payload: { urlBeforeProduct: `${window.location.pathname}${window.location.search}` }
      // });
      dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname}${window.location.search}` }))
      window.history.replaceState({ ...window.history.state, as: item.to, url: item.to }, '', item.to);
    }
    dispatchRedux(pushNotification({ type: item.popup }))
    handleBackdropClick(item.name, 'menu-hamburger');
  };

  const handleClick = useCallback(() => {
    dispatch({ type: 'manageChatBox', payload: { displayChat: { show: true, reload: false } } });
    dispatchRedux(toggleSidebar())
  }, [dispatch])

  const getResolvedOrder = useCallback(async () => {
    dispatch({ type: 'setLoading', payload: true })
    try {
      let orders = await getResolvableOrderList();
      orders?.data?.length && dispatch({ type: 'setChatData', payload: { order_list: orders.data } })

    } catch (error) {
      setRollbarWarning('fetching Resolvable Order List', error)
    } finally {
      dispatch({ type: 'setLoading', payload: false })
    }
  }, [])

  useEffect(() => {
    if (isSideBarOpen) {
      document.body.classList.add('zoom-modal-open');
    }
    if (!isSideBarOpen) {
      document.body.classList.remove('zoom-modal-open');
    }
  }, [isSideBarOpen])


  useEffect(() => {
    isLoggedIn && isACSApplicable && getResolvedOrder();
  }, [isLoggedIn])

  function renderSidebarContent() {
    return sideBarList.map(item => {
      if (item.hideNonGrassdoor === true && !appConfig.isGrassdoor) {
        return null;
      }
      if (item.hide === true) {
        return null;
      }

      if (item.hasSubMenu && item.hasSubMenu.length) {
        return (
          <div key={item.name} role="presentation" className="sidebarItem" onClick={() => showHideSubmenu(item.name)}>
            <div className="sidebarItemStyle">
              <span className={item.icon} />
              <span className="fs-14 fw-600 flex-1 item-n">{item.name}</span>
              <span className="icon-down-arrow-dark right-most" />
            </div>

            {openedSubmenu === item.name && (
              <div className="submenu">
                {item.hasSubMenu.map(subItem => {
                  if (subItem.hideNonGrassdoor === true && !appConfig.isGrassdoor) {
                    return null;
                  }
                  if (subItem.component) {
                    return <subItem.component key={subItem.name} item={subItem} onClick={handleBackdropClick} />;
                  }
                  if (subItem.directLink) {
                    return (
                      <a
                        href={subItem.to}
                        key={subItem.name}
                        className="sidebarItem"
                        onClick={e => {
                          handleNavigationClick(e, subItem.to, subItem.name, 'menu-hamburger-sub', subItem.directLink);
                        }}
                      >
                        <div className="sidebarItemStyle">
                          <span className="fs-16 fw-600">{subItem.name}</span>
                        </div>
                      </a>
                    );
                  }
                  if (subItem.category) {
                    return (
                      <Link href={`${subItem.link}${tab ? `?tab=${tab}` : ``}`} key={subItem.name} className="sidebarItem">
                        <div onClick={() => handleCategoryClick(subItem)} className="sidebarItemStyle">
                          <span className="text-dark">{subItem.name}</span>
                        </div>
                      </Link>
                    );
                  }
                  if (subItem.directLink) {
                    return (
                      <a
                        href={subItem.to}
                        key={subItem.name}
                        className="sidebarItem"
                        onClick={e => {
                          handleNavigationClick(e, subItem.to, subItem.name, 'menu-hamburger-sub');
                        }}
                      >
                        <div className="sidebarItemStyle">
                          <span className="text-dark">{subItem.name}</span>
                        </div>
                      </a>
                    );
                  }
                  return (
                    <ActiveLink
                      key={subItem.name}
                      href={subItem.to}
                      className="sidebarItem"
                      onClick={() => handleBackdropClick(subItem.name, 'menu-hamburger-sub')}
                    >
                      <div className="sidebarItemStyle">
                        <span className="text-dark">{subItem.name}</span>
                      </div>
                    </ActiveLink>
                  );
                })}
              </div>
            )}
          </div>
        );
      }

      if (item.directLink) {
        return (
          <a
            href={item.to}
            key={item.name}
            target={item.directExternalLink && '_blank'}
            rel={item.directExternalLink && 'noreferrer'}
            className="sidebarItem"
            onClick={e => {
              handleNavigationClick(e, item.to, item.name, 'menu-hamburger', item.directExternalLink);
            }}
          >
            <div className="sidebarItemStyle">
              <span className={item.icon} />
              <span className="text-dark">{item.name}</span>
            </div>
          </a>
        );
      }

      if (item.component) {
        return <item.component key={item.name} item={item} onClick={() => handleBackdropClick(item.name, 'menu-hamburger')} />;
      }

      if (item.popup) {
        return (
          <div className={`sidebarItem ${item.className || ''}`} key={item.name}>
            <div onClick={handleMenuPopupClick(item)} className="sidebarItemStyle">
              <span className={item.icon} />
              <span className="fs-14 fw-600 item-n">{item.name}</span>
            </div>
          </div>
        );
      }

      return (
        <ActiveLink
          key={item.name}
          href={item.to}
          className="sidebarItem"
          onClick={() => handleBackdropClick(item.name, 'menu-hamburger')}
          directLink={item.directLink}
        >
          <div className="sidebarItemStyle">
            <span className={item.icon} />
            <span className="fs-14 fw-600 item-n">{item.name}</span>
          </div>
        </ActiveLink>
      );
    });
  }

  return (
    <CSSTransition in={isSideBarOpen} timeout={500} classNames="anim-sidebar" mountOnEnter>
      <div className="sidebar new-sidebar">
        <div className="backdrop" role="presentation" onClick={() =>
          dispatchRedux(toggleSidebar())} />
        <div className="content">
          <div className="sidebarContent pt-0">
            <div className="sidebar-wrap">
              <div className="top-sec">
                <div className="main-logo ">
                  <img
                    data-testid="#main-logo-img"
                    src={imageSrc}
                    className={''}
                    alt={appConfig.APP_NAME}
                  />
                </div>
                <span
                  data-testid="#sidebar-close"
                  onClick={() =>
                    dispatchRedux(toggleSidebar())}
                  className="cursor-pointer icon-close height-100 d-flex align-items-center justify-flex-end bold"
                />
              </div>
              <div test-id="#sidebar_menu_list" className="menu-list">
                {renderSidebarContent()}
              </div>
              {appConfig.FAQ_URL ? (
                // <a href={`${appConfig.FAQ_URL}?faq=true`} rel="noreferrer" className=""> //Add this later 
                // <a href={`/contact`} rel="noreferrer" className="">
                <div className="help-support">
                  <h6 className="mb-0 fs-16 bold">Help & Support</h6>
                  <i className="icon icon-help-support-s" />
                  {!isLoggedIn ? <div className="d-flex align-items-center fs-16 mt-1"><span class="icon-phone-call mr-1"></span>{appConfig.SUPPORT_CONTACT}</div> : null}
                  <div className="d-flex align-items-center fs-16 mt-1"><span class="material-symbols-outlined icon mr-1">forward_to_inbox</span>{appConfig.SUPPORT_EMAIL}</div>
                  {isLoggedIn ? <div onClick={() => handleClick()} className='underline cursor-pointer mt-1 fs-16'><span class="material-symbols-outlined icon mr-1 !text-[24px]">contact_support</span>Connect with us</div> : null}
                </div>
                // </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
export default withRouter(NewSidebar);

const giftCadListGd = [
  { name: 'Purchase Gift Cards', to: '/gift-cards' },
  { name: 'Redeem Gift Cards', to: '/gift-cards-redeem' }
];
