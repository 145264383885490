import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import appContext from '../../Context/appContext';

export default function UserBot({ data, dateTime }) {
  // const {
  //   state: {
  //     cart: {
  //       customerData
  //     }
  //   }
  // } = useContext(appContext);
  const customerData = useSelector(state => state.cart?.customerData) || {}
  // const imageBaseUrl = 'https://saas4-operator1-public-data.s3.us-west-2.amazonaws.com/Users/Grassdoor/acs/defective_product_image/'
  return (
    <>
      <div className="user-bot d-flex justify-flex-end text-right my-3">
        <div className="chat-holder">
          <div className="chat-text">
            {data?.message}
            {
              data.messageType === 'issueSubmitted' && data?.selected?.file_names.length ?
                <div className="add-details p-0 my-2 added-details">
                  <div className="d-flex flex-wrap">
                    {
                      data.selected.file_names.length ?
                        data.selected.file_names.map((file, key) => {
                          return (
                            <div className="img-holder" key={key}>
                              <img src={process.env.NEXT_PUBLIC_ACS_IMAGE_URL + file} />
                            </div>
                          )
                        })
                        :
                        null
                    }
                  </div>
                </div>
                :
                null
            }
          </div>
          <div className="time">{dateTime}</div>
        </div>
        <div className="user-img d-none">
          <div className="name-holder">{customerData.first_name[0] + customerData.last_name[0]}</div>
          {/*<img src="https://dev-serverless.grassdoor.com/filters:format(webp)/pre-grassdoor.grassdoor.com/carousel/desktop/Homepage_banner_1661422648.jpg" alt="profile" />*/}
        </div>
      </div>
    </>
  );
}
