import React, { useEffect, useState, useRef, useContext } from 'react';
import appContext from '../../Context/appContext';
import GrassdoorBot from './GrassdoorBot';
import UserBot from './UserBot';
import Link from 'next/link';
import useAPI from '../../NetworkCall/API';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useConfigData } from '../../Context/ConfigProvider';
import Loader from '../Loader';

export default function ChatBot({ orderId }) {
  const {
    state: {
      chat: { displayChat, data, selectedOptions, chatMessages, loading }
    },
    dispatch
  } = useContext(appContext);
  const appConfig = useConfigData()
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  const chatBody = useRef()
  const { saveChatBotHistory, getPreviousIssueList, getIssueList } = useAPI();

  const addMessage = async (message, selected) => {
    setTimeout(() => { if (chatBody.current) { chatBody.current.scrollIntoView({ behavior: 'smooth' }) } }, 300)
    const time = new Date().toUTCString();
    message.dateTime = time
    let messages = [message]

    switch (message.event) {
      case "productSelected":
        messages.push({ type: 'gd', dateTime: time, options: data.issue_list[selectedOptions.issue_category_id], selectedIssue: selectedOptions, messageType: "issueList", message: "Please tell us your issue, and we'll strive to resolve it right away. Kindly provide more details." })
        chatMessages.splice(message.selected.index + 1)
        chatMessages.splice(message.selected.index + 2)
        messages = [...chatMessages, ...messages]
        dispatch({ type: 'addMessage', payload: { messages, clear: true } });
        break
      case "issueSelected":
        const prevComment = chatMessages[message.selected.index + 2]?.comment ? chatMessages[message.selected.index + 2].comment : ''
        messages.push({ type: 'gd', dateTime: time, addPhotos: true, comment: prevComment, settings: data.issue_list[selectedOptions.issue_category_id][selected.issue_index], message: message.selected.message })
        chatMessages.splice(message.selected.index + 1)
        chatMessages.splice(message.selected.index + 2)
        messages = [...chatMessages, ...messages]
        dispatch({ type: 'addMessage', payload: { messages, clear: true } });
        break
      case "issueProcessing":
        dispatch({ type: 'addMessage', payload: { messages } });
        break
      case "issueSubmited":
        messages.unshift({ type: 'user', dateTime: time, message: selected.comment, selected: selected, messageType: "issueSubmitted" })
        selectedOptions.order_id = data.order_details.order_id
        const historyObj = { order_id: data.order_details.order_id, complaint_id: selected.complaint_id, payload: { chatMessages: filterChatData([...chatMessages, ...messages]), selectedOptions } }
        messages.push({ type: 'gd', dateTime: time, message: 'Is there anything else we can assist with?', messageType: "yes-no" })
        messages = [...chatMessages.filter(x => x.messageType !== 'issueProcessing'), ...messages]

        dispatch({ type: 'addMessage', payload: { messages, clear: true } });

        await saveChatBotHistory(historyObj)
        await getPreviousIssueList(orderId)
        setTimeout(() => { if (chatBody.current) { chatBody.current.scrollIntoView({ behavior: 'smooth' }) } }, 300)
        break;
      case "anyMoreIssue":
        if (messages[0].message === 'Yes') {
          if (data?.warning?.show === true) {
            toast.error(data?.warning?.message)
          }
          messages.push({ type: 'gd', dateTime: time, message: 'Select Category', messageType: "issueCategoryList" })
        }
        if (messages[0].message === 'No') {
          messages.push({ type: 'gd', dateTime: time, message: 'Was this helpful?', messageType: "wasHelpfull" })
        }
        dispatch({ type: 'addMessage', payload: { messages } });
        break;
      case "manageMedia":
        dispatch({ type: 'updateLastMessage', payload: { images: messages[0].images } });
        break;
      case "manageMediaFalse":
        messages.push({ type: 'gd', dateTime: time, message: 'Is there anything else we can assist with?', messageType: "yes-no", selected: selected })
        messages = [...chatMessages.filter(x => x.messageType !== 'issueProcessing'), ...messages]
        dispatch({ type: 'addMessage', payload: { messages, clear: true } });
        break;
      case "manageComment":
        dispatch({ type: 'updateLastMessage', payload: { comment: messages[0].comment } });
        break;
      case "categorySelected":
        if (message.selected.issue_category_id === 1) {
          if (data?.filtered_products.length === data.order_details.products.length) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiries are already submitted for all products. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            messages.push({ type: 'gd', dateTime: time, message: "We apologize for the inconvenience! Please select the product you’re experiencing issues with.", order_details: data.order_details })
          }
        } else if (message.selected.issue_category_id === 2) {
          if (data.issue_categories_reported.includes(message.selected.issue_category_id)) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiry is already submitted for this category. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            // messages.push({type: 'gd', dateTime: time, selected: {issue_list_id: data.issue_list[message.selected.issue_category_id][0].issue_id}, message:'Please let us know what is your issue. And we will try to resolve this immediately.'}) 
            messages.push({ type: 'gd', dateTime: time, addPhotos: true, selected: { issue_list_id: data.issue_list[message.selected.issue_category_id][0].issue_id }, settings: data.issue_list[message.selected.issue_category_id][0], message: "Please tell us your issue, and we'll strive to resolve it right away. Kindly provide more details. <br/><br/>" + data.issue_list[message.selected.issue_category_id][0].message })
          }
        } else if (message.selected.issue_category_id === 4) {
          // messages.push({ type: 'gd', dateTime: time, message: message.message })
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          // if (isSafari) {
          //   const windowReference = window.open();
          //   setTimeout(() => {
          //     windowReference.location = appConfig.FAQ_URL;
          //   }, 2000)
          // } else {
          //   setTimeout(() => {
          //     window.open(appConfig.FAQ_URL, '_blank');
          //   }, 2000)
          // }
        } else {
          if (data.issue_categories_reported.includes(message.selected.issue_category_id)) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiry is already submitted for this category. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            messages.push({ type: 'gd', dateTime: time, options: data.issue_list[message.selected.issue_category_id], selectedIssue: selectedOptions, messageType: "issueList", message: "Please tell us your issue, and we'll strive to resolve it right away. Kindly provide more details." })
          }
        }

        if ((message.selected.index === 0 || message.selected.index) && chatMessages.length > message.selected.index + 1) {

          chatMessages.splice(message.selected.index + 1)
          chatMessages.splice(message.selected.index + 2)
          messages = [...chatMessages, ...messages]
          dispatch({ type: 'removeMessage' })
          dispatch({ type: 'addMessage', payload: { messages, clear: true } });
        } else {
          dispatch({ type: 'removeMessage' })
          dispatch({ type: 'addMessage', payload: { messages } });
        }
        break;
      case "wasHelpfull":
        dispatch({ type: 'addMessage', payload: { messages } });
        break;
      case "orderSelected":

        dispatch({ type: 'addMessage', payload: { messages: [{ type: 'gd', dateTime: time, message: '<div class="loader-text"><span class="inline-loader"></span> Please wait. We are processing your request...</div>', messageType: "issueProcessing", event: "issueProcessing" }] } });
        const response = await getIssueList(message?.selected?.order_id);
        dispatch({ type: 'removeMessage' })
        if (response?.data?.issue_list) {
          const mapDataParsed = {};
          response.data.issue_list.map(list => {
            mapDataParsed[Object.keys(list)[0]] = list[Object.keys(list)[0]];
            return null;
          });
          response.data.issue_list = mapDataParsed;
        }
        if (response.status === true) {
          dispatch({ type: 'setChatData', payload: { ...response.data, order_list: data.order_list } });
        }
        messages.push({ type: 'gd', dateTime: time, message: 'Please Select Category', messageType: "issueCategoryList" })

        // dispatch({ type: 'removeMessage' })
        dispatch({ type: 'addMessage', payload: { messages } });
        break;
      default:
        dispatch({ type: 'addMessage', payload: { messages } });
        break
    }
  }

  const filterChatData = (chats) => {
    let cData = []
    chats.map((chat) => {
      if (chat.messageType === 'issueCategoryList') {
        cData = []
      }
      if (chat.messageType !== 'issueProcessing') {
        cData.push(chat)
      }
      return null
    })

    return cData
  }


  const closeChatBox = () => {
    document.body.className = document.body.className.replace("modal-open", "");
    dispatch({ type: 'manageChatBox', payload: { displayChat: { show: false } } });
  }

  const toggleMinimizeChat = () => {
    dispatch({ type: 'manageChatBox', payload: { displayChat: { minimize: !displayChat.minimize } } });
  }

  const formatTwoDigits = (t) => {
    const v = '' + t
    if (v.length === 1) { return '0' + t } else { return t }
  }


  const formatDateTime = (time) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const gmtDate = new Date(time);

    var d = gmtDate.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).split(',')

    const dateString = d[0].split('/')

    return months[dateString[0] - 1] + ' ' + dateString[1] + ', ' + d[1]
  }


  useEffect(() => {
    let chatbotClose = 0
    //if(data?.order_details?.order_id) { if(data.order_details.order_id !== orderId) { chatbotClose = 1; closeChatBox() } }
    if (displayChat?.show === true && displayChat?.reload !== true && selectedOptions.chatOpen !== true && isLoggedIn && chatbotClose === 0) {
      if (!document.body.classList.contains('modal-open')) { document.body.className += ' modal-open'; }
      const time = new Date().toUTCString();
      let messages = [{ message: selectedOptions.issue_category_label, dateTime: time, type: 'user' }]
      switch (selectedOptions.issue_category_id) {
        case 1:
          if (data?.filtered_products.length === data.order_details.products.length) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiries are already submitted for all products. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            messages.push({ type: 'gd', dateTime: time, message: "We apologize for the inconvenience! Please select the product you’re experiencing issues with.", order_details: data.order_details })
          }
          break;
        case 2:
        case 4:
          if (data.issue_categories_reported.includes(selectedOptions.issue_category_id)) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiry is already submitted for this category. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            messages.push({ type: 'gd', dateTime: time, selected: { issue_list_id: data.issue_list[selectedOptions.issue_category_id][0].issue_id }, message: "Please tell us your issue, and we'll strive to resolve it right away. Kindly provide more details." })
            messages.push({ type: 'gd', dateTime: time, addPhotos: true, settings: data.issue_list[selectedOptions.issue_category_id][0], message: data.issue_list[selectedOptions.issue_category_id][0].message })
          }
          break;
        case 3:
          if (data.issue_categories_reported.includes(selectedOptions.issue_category_id)) {
            messages.push({ type: 'gd', dateTime: time, message: 'Enquiry is already submitted for this category. Is there anything else we can assist with?', messageType: "yes-no" })
          } else {
            messages.push({ type: 'gd', dateTime: time, options: data.issue_list[selectedOptions.issue_category_id], selectedIssue: selectedOptions, messageType: "issueList", message: "Please tell us your issue, and we'll strive to resolve it right away. Kindly provide more details." })
            dispatch({ type: 'addMessage', payload: { messages } });
          }
          break;
        default:
          messages = !orderId ?
            [{ type: 'gd', dateTime: time, message: 'Please select the order with the issue', messageType: "orderList" }]
            : [{ type: 'gd', dateTime: time, message: 'Please Select Category', messageType: "issueCategoryList" }]
          break;
      }
      setTimeout(() => { if (chatBody.current) { chatBody.current.scrollIntoView({ behavior: 'smooth' }) } }, 300)
      dispatch({ type: 'addMessage', payload: { clear: true, messages } });
    } else {
      if (displayChat?.show && isLoggedIn && !document.body.classList.contains('modal-open')) {
        document.body.className += ' modal-open';
      }
    }

  }, [displayChat.show, displayChat.reload, selectedOptions.issue_category_id])

  useEffect(() => {
    return (() => {
      closeChatBox()
    })
  }, [])

  return (
    displayChat?.show && isLoggedIn ? (
      <div className={`${displayChat?.show === false ? `d-none` : ``} ${displayChat?.minimize ? `minimize` : `maximize`} modal age-popup d-block chat-bot-container animated-popup-wrapper slideup-wrapper-appear-done`} tabIndex="-1" role="dialog">
        <div className="modal-dialog m-scroll" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-between align-items-center border-bottom-0">
              <h6 className="modal-title text-center bold d-flex align-items-center">
                <span class="material-symbols-outlined icon mr-2 !text-[24px] popup-title-icon mb-0 leading-[40px]">contact_support</span>
                Resolution Center
              </h6>
              <div>
                <button type="button" className="btn close-btn" onClick={closeChatBox}>
                  <i className="icon-close" />
                </button>
              </div>
            </div>
            <div className="modal-body p-0">
              {
                !orderId && !data.order_list ?
                  loading ?
                    <div className='chat-body'>
                      <Loader />
                    </div>
                    :
                    <div className='chat-body'>
                      <div className="no-orders rounded-[4px] bg-[#FDEDED] p-[15px] d-flex">
                        <span class="material-symbols-outlined text-[#D32F2F] text-[24px] mr-2">error</span>
                        <div className="text-[#5F2120] text-[14px]">
                          <h5 className='fs-16 font-[500] mb-2 mt-[3px]'>No Orders Eligible for Refund</h5>
                          Refund is provided for specific situations only within 24 hours of ordering
                        </div>
                      </div>
                    </div>
                  :
                  <div className={`chat-body ${selectedOptions.wasThishelpfull ? 'inactive' : ''}`}>
                    {
                      chatMessages.map((botMessage, key) => {
                        return (
                          botMessage.type === 'user' ?
                            <UserBot key={key} data={botMessage} dateTime={formatDateTime(botMessage.dateTime)} />
                            :
                            <GrassdoorBot key={key} index={key} totalMessages={chatMessages.length - 1} orderId={data?.order_details?.order_id} messageData={botMessage} dateTime={formatDateTime(botMessage.dateTime)} clickHandler={addMessage} />
                        )
                      })
                    }
                    <div style={{ height: '80px' }} id="chatBody" ref={chatBody}></div>
                  </div>
              }
              {
                selectedOptions.wasThishelpfull ?
                  <div className='chat-message'>
                    {/* We are closing this issue here if you have any more queries kindly check in <a href='#'>My order issues</a> Or <Link href="/contact">Contact us</Link> */}
                    We are closing this issue, if you have any more queries kindly <a href={`tel:${appConfig.SUPPORT_CONTACT}`} className="ml-[5px] text-decoration-none">Contact Us</a>
                  </div>
                  :
                  null
              }
            </div>
            <div className='modal-footer justify-content-start'>
              <p>For all queries, you can contact <a href={`tel:${appConfig.SUPPORT_CONTACT}`} className="ml-[5px] text-decoration-none">{appConfig.SUPPORT_CONTACT}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
      :
      null
  );
}